import ReactRailsUJS from 'react_ujs'; // Ensure this line is present

// Support component names relative to this directory:
let componentRequireContext = require.context("components", true);
// let ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Prevent double mount on page load
ReactRailsUJS._firstTurboLoadSkipped = false
ReactRailsUJS.handleEvent('turbo:load', () => {
  if (ReactRailsUJS._firstTurboLoadSkipped) ReactRailsUJS.handleMount()
  ReactRailsUJS._firstTurboLoadSkipped = true
})

// Unmount components and call cleanup functions after Turbo navigations
ReactRailsUJS.handleEvent('turbo:before-render', (e) => {
  ReactRailsUJS.unmountComponents(e.target)
})