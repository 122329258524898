import React from "react"
import PropTypes from "prop-types"

const BookListItem = (props) => {
  const { title, author, publisher, cover_url, url } = props
  return (
    <div className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
      <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
        <img src={cover_url} alt="" className="h-full w-full object-containc object-center sm:h-full sm:w-full" />
      </div>
      <div className="flex flex-1 flex-col space-y-2 p-4">
        <h3 className="text-sm font-medium text-gray-900">
          <a href={url}>
            <span aria-hidden="true" className="absolute inset-0"></span>
            {title}
          </a>
        </h3>
        <p className="text-sm text-gray-500">{author}</p>
        <div className="flex flex-1 flex-col justify-end">
          <p className="text-sm italic text-gray-500">{publisher}</p>
        </div>
      </div>
    </div>
  )
}

BookListItem.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  publisher: PropTypes.string,
  cover_url: PropTypes.string,
  url: PropTypes.string
};

export default BookListItem
