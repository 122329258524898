import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

const BookDanbi = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // async function definition
    const fetchData = async () => {
      try {
        const response = await fetch("/books/book_danbi.json");
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="mt-4">
      <h3>책단비 현황</h3>
      <div className="flex overflow-x-auto scrollbar-hide">
        <ul className="flex flex-nowrap space-x-4 py-2">
        {data.map((item) => (
            <li key={item.title} className="min-w-max">
              <span className={`inline-block ${item.value > 0 ? "bg-blue-100 text-blue-600" : "bg-gray-100 text-gray-600"} py-2 px-4 rounded-full`}><b>{item.title}</b> {item.value}권</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

BookDanbi.propTypes = {
  // data: PropTypes.array
};

export default BookDanbi
