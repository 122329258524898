import React from "react"
import PropTypes from "prop-types"
import { InformationCircleIcon } from '@heroicons/react/20/solid'

const AlertInfo = (props) => {
  return (
    <div className="rounded-md bg-blue-50 p-4 mt-1">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">{props.message}</p>
        </div>
      </div>
    </div>
  )
}

AlertInfo.propTypes = {
  message: PropTypes.string
};

export default AlertInfo
